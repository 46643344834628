/*
 * Copyright 2023 General Code
 */

.FLX .definition .term, .FLX .definition .term .termLink {
  text-decoration: underline;
  font-weight: normal;
  font-size: inherit;
  font-style: italic;
  display: inline;
}

.FLX .definition .term .termLink {
  padding-right: 4px;
}

.FLX .definition .deftext {
  display: inline;
  margin: 0;
}

.FLX .history {
  margin-top: 14px;
}